.Modal_Assinatura{
  .ReactModal__Content{ }
  .bt-close-modal{
    top: -35px;
  }

  .nome-cliente{
    padding: 3px 4px;
    display: block;
  }
}

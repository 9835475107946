.Modal_AgendamentosAjax .ReactModal__Content {
  max-width: 800px;
  max-height: 100%;
}
.Modal_AgendamentosAjax .bt-close-modal {
  top: -35px;
}
.Modal_AgendamentosAjax .nome-cliente {
  padding: 3px 4px;
  display: block;
}
.Modal_AgendamentosAjax .box-button-aplicar-agendamento {
  padding: 0 10px;
}
.Modal_AgendamentosAjax .box-button-aplicar-agendamento .btn {
  margin: 0;
}
.Modal_AgendamentosAjax .td-hour-dinamic {
  cursor: pointer;
}
.Modal_AgendamentosAjax .active_hour_dinamic .td-hour-dinamic {
  background: #e74c3c;
  color: #ffffff;
}
.Modal_Delete .ReactModal__Content {
  width: 400px;
  max-height: 100%;
}
.Modal_Delete .bt-close-modal {
  top: -35px;
}
.Modal_Delete .title-modal {
  font-size: 24px;
  line-height: 28px;
}
.loader{
    background: rgba(0,0,0,.9);
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .loader__wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
  }
  
  .content-blocker-loading{
    .fa{
      color: #ffffff;
    }
  }
  
  .loader__box{
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
.Modal_Caixa .ReactModal__Content {
  width: 500px;
  max-height: 100%;
  overflow: scroll !important;
}
.Modal_Caixa .bt-close-modal {
  top: -35px;
}
.Modal_Caixa .nome-cliente {
  padding: 3px 4px;
  display: block;
}
.Modal_Caixa .box-button-aplicar-agendamento {
  padding: 0 10px;
}
.Modal_Caixa .box-button-aplicar-agendamento .btn {
  margin: 0;
}
.Modal_Caixa .td-hour-dinamic {
  cursor: pointer;
}
.Modal_Caixa .active_hour_dinamic .td-hour-dinamic {
  background: #e74c3c;
  color: #ffffff;
}

.Modal_Busca_CAIXA .ReactModal__Content {
  width: 500px;
  max-height: 100%;
  overflow: visible !important;
}
.th-comissao {
  width: 100px;
}

.th-tipo-comissao {
  width: 100px;
}

.th-valor-comissao {
  width: 150px;
}

.box-anexo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-anexo .fa {
  font-size: 15px;
  margin-right: 10px;
}
.box-anexo input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.input-arquivo-conta {
  margin-top: 30px;
}
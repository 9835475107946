.button-finalizar-agendamento a{
  color: #ffffff !important;
}


.Modal_Agendamento .ReactModal__Content {
  width: 800px;
  max-height: 100%;
}

.Modal_Agendamento .bt-close-modal {
  position: absolute;
  right: -10px;
  top: -35px;
  font-size: 20px;
}

.box-modal-visualizacao {
  position: relative;
}
.box-image-dashboard{
  padding: 150px 0;
  text-align: center;
  span{
    display: block;
    font-size: 20px;
    margin-top: 20px;
  }
  img{
    display: inline-block;
  }
}

.info-box-icon .fa{
  color: #ffffff;
}

.error-page {
  padding: 100px 0;
  text-align: center;
}
.error-page .fa {
  font-size: 100px;
  opacity: 0.5;
}
.error-page span {
  font-size: 17px;
  line-height: 20px;
  display: block;
  margin-top: 20px;
}
.checkbox input {
  margin-right: 10px;
}

.box-body__group-checkbox,
.box-body__group-radio {
  padding: 0;
}
.box-body__group-checkbox > div,
.box-body__group-radio > div {
  padding-left: 0;
}

.box-body__group-radio label {
  padding: 0;
}
.ReactModal__Overlay{
  z-index: 99999;
}

body{
  font-size: 14px;
}

.input-table{
  input{
    width: 100%;
  }
}

.tipo-porc{
  margin-right: 10px;
}

.table-actions-single{
  width: 60px;
}

.table-actions-3{
  width: 150px;
}

.field-mandatory{
  label{
    color: #b10000;
  }
}

.tr-cortesia{
  background-color: #f9f9f9;
}

.modal-no-overflow{
  .ReactModal__Content{
    overflow: visible !important;
  }
}

.obs-input{
  font-size: 12px;
}

*.cursor-disabled{
  cursor: not-allowed !important;
  color: #999999;
}

.MODAL_NO_OVERFLOW{
  .ReactModal__Content{
    overflow: visible !important;
  }
}

.main-sidebar{
  height: 100%;
  //overflow-y: scroll;
}

.sidebar-collapse{
  .box-date-menu{
    display: none;
  }
}

.btn-import-file{
  position: relative;
  input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
  }
}

.total_reports{
  font-size: 20px;
  font-weight: 600;
}

.Modal_Venda .bt-close-modal,
.Modal_CRM .bt-close-modal{
  z-index: 9999;
  position: fixed;
  right: 0;
  top: 0;
  font-size: 40px;

  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.clear{
  clear: both;
}

.box-check-itens{
  .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    font-size: 12px;
    height: 35px;
  }
}

.description-report{
  margin-bottom: 30px;
  background: #eeeeee;
  padding: 15px;
}

.table-wrapper{
  width: 100%;
  overflow-x: scroll;
}

/*PRINT*/
@page {
  size: auto;
  margin: 0;
}

tbody tr:nth-child(odd) {
   background-color: #eeeeee;
}
body {-webkit-print-color-adjust: exact;}

.ambiente-sistema.ambiente-sistema--white{
  color: #ffffff;
  float: left;
}

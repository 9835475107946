.item-historico-agendamento {
  margin-bottom: 10px;
  border-bottom: #eeeeee;
  padding: 10px;
}

.i-h-a__date {
  display: block;
  color: #aaaaaa;
}

.i-h-a__text {
  display: block;
  font-size: 17px;
  line-height: 20px;
}

.item-historico-agendamento:nth-of-type(odd) {
  background-color: #efefef;
}

.line-hist {
  margin: 0;
  font-size: 14px;
}
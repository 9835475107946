.Modal_Dados_login{
  .ReactModal__Content{ width: 400px; max-height: 100%;}
  .bt-close-modal{
    top: -35px;
  }

  .title-modal{
    font-size: 24px;
    line-height: 28px;
  }
}

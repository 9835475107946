.pagination-box {
  border-top: 1px solid #d6d6d6;
  padding: 10px 60px 0 0;
}
.pagination-box p {
  float: left;
  font-size: 12px;
  line-height: 15px;
  padding: 10px 10px 0 0;
}
.pagination-box .custom-page {
  float: left;
  width: 50px;
  margin-left: 5px;
}
.pagination-box .custom-page input {
  padding: 5px 12px;
}
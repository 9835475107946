.Modal_Servico{
  .ReactModal__Content{ width: 1000px; max-height: 100%;}
  .bt-close-modal{
    top: -35px;
  }
}



@media screen and (max-width: 800px) {
  .Modal_Servico{
    .ReactModal__Content{ width: 100%;}
  }
}

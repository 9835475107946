.error-page{
  padding: 100px 0;
  text-align: center;

  .fa{
    font-size: 100px;
    opacity: .5;
  }

  span{
    font-size: 17px;
    line-height: 20px;
    display: block;
    margin-top: 20px;
  }
}

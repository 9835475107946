.ModalAlterarPasta .ReactModal__Content {
  width: 400px;
  max-height: 100%;
}
.ModalAlterarPasta .bt-close-modal {
  top: -35px;
}
.ModalAlterarPasta .title-modal {
  font-size: 24px;
  line-height: 28px;
}
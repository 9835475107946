.main-sidebar.prevent-menu-scrolling {
  overflow: hidden;
}

.timer-menu-select .col-xs-2,
.timer-menu-select .col-xs-12 {
  padding: 0 4px;
}

.box-date-menu__wrapper {
  width: 200px;
}

#container-filtro {
  background: #ffffff;
}
#container-filtro .box-modal-visualizacao {
  padding: 10px;
}
#container-filtro .box-modal-visualizacao__wrapper {
  margin-bottom: 10px;
}
#container-filtro .box-modal-visualizacao__title {
  font-size: 14px;
}
#container-filtro .box-modal-visualizacao__item {
  margin-bottom: 0;
}
#container-filtro .box-modal-visualizacao__item span {
  font-size: 10px;
}
.main-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

button {
  margin-left: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: none;
  border: none;
}

.main-header {
  z-index: 900;
}

.table-actions {
  width: 150px;
}

.table-actions-delete {
  width: 60px;
}

body {
  background-color: #ecf0f5;
}

button:focus,
button:active {
  outline: none;
}

html,
body,
#root {
  min-height: 100%;
  position: relative;
}

.login-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  margin: 0;
}

.wrapper-login-box {
  max-width: 350px;
  margin: 100px auto;
  padding: 20px;
}

.form-control {
  height: auto;
  padding: 10px 12px;
  font-size: 16px;
}

.skin-blue .main-header .navbar {
  background: #00632e;
}

.skin-blue .main-header .logo {
  background: #004a22;
}

.skin-blue .wrapper, .skin-blue .main-sidebar, .skin-blue .left-side {
  background: #00632e;
}

.skin-blue .sidebar-menu > li > a:hover {
  background: #007235;
}

.skin-blue .sidebar-menu > li > a:hover {
  border-left-color: #002612;
}

.box-date-menu {
  padding: 15px;
}
.box-date-menu .react-datepicker {
  width: 100%;
}

.react-datepicker__day, .react-datepicker__month-text {
  cursor: pointer;
  font-size: 11px;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 12% !important;
  font-size: 11px;
  line-height: 20px !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 12px !important;
}

.react-datepicker__day.react-datepicker__day--outside-month {
  color: #9a9a9a;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  font-size: 12px;
  line-height: 14px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box,
.react-datepicker__time-container {
  width: 90px !important;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
.col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
.col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
.col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
.col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
.col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-right: 10px;
  padding-left: 10px;
}

.timer-menu-select label {
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 3px;
}
.timer-menu-select .col-xs-12.col-sm-6 {
  padding-left: 0 !important;
}
.timer-menu-select .form-control {
  padding: 3px 10px;
}
.timer-menu-select .form-group {
  margin-bottom: 0;
}
.timer-menu-select .btn {
  background: none;
  margin: 20px 0 0 0 !important;
  border: none;
}
.timer-menu-select .btn .fa {
  color: #ffffff;
  font-size: 18px;
  line-height: 20px;
}

.name-user-notification {
  padding-left: 5px;
}

.pagination-arrows {
  padding-right: 10px;
}

.direct-chat-text {
  display: inline-block;
}

.direct-chat-msg.right .direct-chat-text {
  float: right;
}

.direct-chat-msg {
  margin-bottom: 20px;
}
.direct-chat-msg .direct-chat-text {
  margin: 0;
}

.mailbox-not-read {
  font-weight: 600;
}

.mailbox-item {
  opacity: 0.5;
}

.mailbox-item:hover {
  opacity: 1;
}

.ReactModal__Body--open {
  overflow: hidden;
}
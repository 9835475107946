.box-image-dashboard {
  padding: 150px 0;
  text-align: center;
}
.box-image-dashboard span {
  display: block;
  font-size: 20px;
  margin-top: 20px;
}
.box-image-dashboard img {
  display: inline-block;
}

.info-box-icon .fa {
  color: #ffffff;
}
.form-group__counter {
  position: relative;
}
.form-group__counter .counter {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 24px;
  border-left: 1px solid #cccccc;
}
.form-group__counter .counter-box {
  position: relative;
}
.form-group__counter .counter-box button {
  display: block;
  height: 50%;
  padding: 0;
  font-size: 10px;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #cccccc;
}
.form-group__counter .counter-box button:last-child {
  border: none;
}
.form-group__counter .form-control {
  padding-right: 23px;
}
.box-image-user img {
  width: 100%;
}

.box-image-user__buttons {
  padding-top: 10px;
}
.box-image-user__buttons .btn {
  margin: 0;
}

.wrapper-img-user {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: #eeeeee;
  cursor: pointer;
}
.box-user {
  background: #dddddd;
  min-height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-user .fa {
  font-size: 100px;
}
.box-user input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
.box-user img {
  width: 100%;
}
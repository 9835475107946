@import "../assets/sass/colors";

.wrapper {
    background-color: #fff!important;
}

.login-box{

  .login-box button {
      margin-left: 0px;
  }

  .login-box a:hover {
      cursor: pointer;
  }

  .brand-login{
    max-width: 100%;
    padding: 0 40px;
  }

  .login-box-msg, .register-box-msg{
    font-size: 20px;
    line-height: 28px;
  }

  button{
    background: $mainColor;
  }

  .btn-login{
    padding: 10px 12px;
    font-size: 16px;
    margin: 0;
  }

  .btn-login:hover{
    background: darken($mainColor,5%);
  }

  .form-control-feedback{
    top: 5px;
  }
}

.ambiente-sistema{
  font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    color: #aaaaaa;
}

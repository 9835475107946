.ModalServicoAgendamentos{
  .ReactModal__Content{ width: 1000px; max-height: 100%;}
  .bt-close-modal{
    top: -35px;
  }
}

.text-center{
  text-align: center;
}

@media screen and (max-width: 800px) {
  .ModalServicoAgendamentos{
    .ReactModal__Content{ width: 100%;}
  }
}

.Modal_Alert_Financeiro{
  .ReactModal__Content{ width: 400px; max-height: 100%;}
  .bt-close-modal{
    top: -35px;
  }

  .title-modal{
    font-size: 24px;
    line-height: 28px;
    color: #b10000;
    font-weight: 600;
  }

  .box-body{
    font-size: 18px;
    line-height: 24px;
  }

  .box-buttons-financeiro-pagamento{
    padding-top: 50px;

    .btn{
      margin-bottom: 10px;
    }
  }
}

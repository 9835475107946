.Modal_Financeiro_Agendamento .ReactModal__Content {
  width: 800px;
  max-height: 100%;
}
.Modal_Financeiro_Agendamento .bt-close-modal {
  top: -35px;
}
.Modal_Financeiro_Agendamento .box-tools.pull-right {
  display: none;
}
.Modal_Financeiro_Agendamento .box.box-success {
  border: none;
}
.Modal_Financeiro_Agendamento .box-servico-valor {
  font-size: 20px;
  padding: 0 0 0 10px;
  font-weight: 600;
}
.Modal_Financeiro_Agendamento .n-venda-financeiro-servico {
  padding: 0 0 0 10px;
  font-weight: 600;
}
.Modal_Financeiro_Agendamento .msg-financeiro-servico {
  padding: 3px 3px 3px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
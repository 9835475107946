.form-group__date .react-datepicker {
  width: 100%;
}

.form-group__date-picker .react-datepicker-wrapper {
  width: 100%;
}
.form-group__date-picker .react-datepicker__input-container {
  width: 100%;
  /*input{
    border: none;
    background: none;
    width: 100%;
  }*/
}
.form-group__date-picker .react-datepicker__day-name, .form-group__date-picker .react-datepicker__day, .form-group__date-picker .react-datepicker__time-name {
  width: 30px !important;
}
.bottom-planos {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  left: 0;
}
.bottom-planos .txt-preco-final {
  font-size: 25px;
  line-height: 30px;
  margin-top: 4px;
}
.bottom-planos .txt-preco-final_1 {
  opacity: 0.5;
}
.bottom-planos .txt-preco-parcial {
  opacity: 0.5;
}
.bottom-planos .box-left-bottom-planos {
  padding-top: 10px;
}
.bottom-planos .txt-desconto {
  margin: 0 5px 0 20px;
}
.bottom-planos .box-type-desconto {
  margin-left: 20px;
}
.bottom-planos .box-type-desconto .pull-left {
  margin-right: 15px;
}
.bottom-planos .input-desconto {
  width: 70px;
}

.bottom-planos__box {
  margin-left: 230px;
  height: 60px;
  background: green;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #ffffff;
}

.sidebar-collapse .bottom-planos__box {
  margin-left: 50px;
}

.bottom-plano-box-body {
  position: relative;
}

.center-bottom-planos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.center-bottom-planos .btn {
  margin: 14px 0 0 0;
  min-width: 200px;
  font-weight: 900;
  text-transform: uppercase;
}
.Modal_Financeiro_Agendamento{
  .ReactModal__Content{ width: 800px; max-height: 100%;}
  .bt-close-modal{
    top: -35px;
  }

  .box-tools.pull-right{
    display: none;
  }

  .box.box-success{
    border: none;
  }

  .box-servico-valor{
    font-size: 20px;
    padding: 0 0 0 10px;
    font-weight: 600;
  }

  .n-venda-financeiro-servico{
    padding: 0 0 0 10px;
    font-weight: 600;
  }

  .msg-financeiro-servico{
    padding: 3px 3px 3px 10px;
    margin-bottom: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
}

.th-parcela{
  width: 60px;
}

.txt-folder-name{
  padding-left: 5px;
}

.box-folder-pictures{
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 5px 20px;
}

.btn-v-p{
  height: 60px;
  margin-bottom: 20px;

  .txt{
    display: block;
  }
}

.wrapper-remove-folder{
  text-align: right;
  padding: 0 20px 20px 20px;
}

.assinatura-cliente{
 img{
   max-width: 100%;
   margin-top: 10px;
 }
 .btn-remover-assinatura{
   margin-left: 0;
 }
 .content-assinatura{
   display: inline-block;
   margin-bottom: 20px;
 }
}

.ficha-assinatura{
  float: left;
  width: 20%;

  img{
    max-width: 100%;
  }
}

.ficha-detalhe{
  float: left;
  width: 80%;
}

.ficha-detalhe__box{
  padding: 10px 0 0 20px;
}

.ficha-box{
  margin-bottom: 20px;
}

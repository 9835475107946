$h: 60px;
.bottom-planos{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: $h;
  left: 0;

  .txt-preco-final{
    font-size: 25px;
    line-height: 30px;
    margin-top: 4px;
  }

  .txt-preco-final_1{
    opacity: .5;
  }

  .txt-preco-parcial{
    opacity: .5;
  }

  .box-left-bottom-planos{
    padding-top: 10px;
  }

  .txt-desconto{
    margin: 0 5px 0 20px;
  }

  .box-type-desconto{
    margin-left: 20px;

    .pull-left{
      margin-right: 15px;
    }
  }

  .input-desconto{
    width: 70px;
  }
}

.bottom-planos__box{
  margin-left: 230px;
  height: $h;
  background: green;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  color: #ffffff;

}

.sidebar-collapse{
  .bottom-planos__box{
    margin-left: 50px;
  }
}

.bottom-plano-box-body{
  position: relative;
}

.center-bottom-planos{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;

  .btn{
    margin: 14px 0 0 0;
    min-width: 200px;
    font-weight: 900;
    text-transform: uppercase;
  }
}

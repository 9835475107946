.Modal_Alert_Force_Pay .ReactModal__Content {
  width: 400px;
  max-height: 100%;
}
.Modal_Alert_Force_Pay .bt-close-modal {
  top: -35px;
}
.Modal_Alert_Force_Pay .title-modal {
  font-size: 24px;
  line-height: 28px;
  color: #b10000;
  font-weight: 600;
}
.Modal_Alert_Force_Pay .box-body {
  font-size: 18px;
  line-height: 24px;
}
.Modal_Alert_Force_Pay .box-buttons-financeiro-pagamento {
  padding-top: 50px;
}
.Modal_Alert_Force_Pay .box-buttons-financeiro-pagamento .btn {
  margin-bottom: 10px;
}
.Modal_AgendamentosAjax{
  .ReactModal__Content{ max-width: 800px; max-height: 100%;}
  .bt-close-modal{
    top: -35px;
  }

  .nome-cliente{
    padding: 3px 4px;
    display: block;
  }

  .box-button-aplicar-agendamento{
    padding: 0 10px;
    .btn{
      margin: 0;
    }
  }

  .td-hour-dinamic{
    cursor: pointer;
  }

  .active_hour_dinamic .td-hour-dinamic{
    background: #e74c3c;
    color: #ffffff;
  }
}

.wrapper {
  background-color: #fff !important;
}

.login-box .login-box button {
  margin-left: 0px;
}
.login-box .login-box a:hover {
  cursor: pointer;
}
.login-box .brand-login {
  max-width: 100%;
  padding: 0 40px;
}
.login-box .login-box-msg, .login-box .register-box-msg {
  font-size: 20px;
  line-height: 28px;
}
.login-box button {
  background: #00632e;
}
.login-box .btn-login {
  padding: 10px 12px;
  font-size: 16px;
  margin: 0;
}
.login-box .btn-login:hover {
  background: #004a22;
}
.login-box .form-control-feedback {
  top: 5px;
}

.ambiente-sistema {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  color: #aaaaaa;
}
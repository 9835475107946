.main-sidebar.prevent-menu-scrolling{
  overflow: hidden;
}

.timer-menu-select{
  .col-xs-2,
  .col-xs-12{
    padding: 0 4px;
  }
}

.box-date-menu__wrapper{
  width: 200px;
}

#container-filtro{
  background: #ffffff;

  .box-modal-visualizacao{
    padding: 10px;
  }

  .box-modal-visualizacao__wrapper{
      margin-bottom: 10px;
  }

  .box-modal-visualizacao__title{
    font-size: 14px;
  }

  .box-modal-visualizacao__item{
    margin-bottom: 0;
  }

  .box-modal-visualizacao__item span{
    font-size: 10px;
  }
}

.Modal_Plano .ReactModal__Content {
  max-width: 1000px;
  max-height: 100%;
}
.Modal_Plano .bt-close-modal {
  top: -35px;
}
.Modal_Plano .title-modal {
  font-size: 24px;
  line-height: 28px;
}

@media screen and (max-width: 800px) {
  .Modal_Plano .ReactModal__Content {
    width: 100%;
  }
}